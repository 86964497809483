// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import OutsideClick from 'react-onclickout'
import update from 'immutability-helper'
import moment from 'moment'

import Button from '../../../Button'
import Item from './VariableItem'
import { MAX_VARIABLES_COUNT, VariableTypes } from '../../constants'

type State = {
  errors: Array<number>,
  items: Array<Object>,
}
type Props = {
  active: boolean,
  canSaveOnUnmount: () => boolean,
  data: Object,
  error: ?Object,
  onClickOut: () => void,
  onUpdate: (number, Object) => void,
  t: string => string,
  updatingId: ?number,
  variables: Array<Object>,
}

class Variable extends Component<Props, State> {
  state = {
    errors: [],
    items: this.props.data.variables,
  }

  componentDidUpdate(prevProps: Props) {
    const { active, data, updatingId } = prevProps

    if (active && !this.props.active) {
      this.saveVariables()
    }

    if (!this.props.error && updatingId === data.id && !this.props.updatingId) {
      this.setState({ items: this.props.data.variables })
    }
  }

  componentWillUnmount() {
    if (this.props.canSaveOnUnmount()) {
      this.saveVariables(true)
    }
  }

  getItemForbiddenNames = item =>
    this.props.variables.filter(v => v.id !== item.id).map(v => v.name)

  setItemError = i => {
    this.setState(state => ({
      errors: [...state.errors, i],
    }))
  }

  addItem = () => {
    const { items } = this.state
    this.setState({
      items: [
        ...items,
        {
          key: moment().valueOf().toString(),
          required: false,
          type: VariableTypes.string,
        },
      ],
    })
  }

  updateItem = i => item => {
    const { items, errors } = this.state

    this.setState({
      items: update(items, {
        [i]: { $set: item },
      }),
      errors:
        item.name && item.name.trim() ? errors.filter(e => e !== i) : errors,
    })
  }

  removeItem = i => () => {
    this.setState(state =>
      update(state, {
        items: { $splice: [[i, 1]] },
        errors: { $set: state.errors.filter(e => e !== i) },
      })
    )
  }

  validateItem = i => () => {
    const item = this.state.items[i]
    const forbiddenNames = this.getItemForbiddenNames(item)
    const dupicateNames = this.getDupicateNames()

    if (!item.name) {
      this.setItemError(i)

      return false
    }

    if (
      forbiddenNames.includes(item.name.trim()) ||
      dupicateNames.includes(item.name.trim())
    ) {
      this.setItemError(i)
      this.setState({ nameIsForbidden: true })

      return false
    }

    return true
  }

  saveVariables = saveValid => {
    const { items, errors } = this.state

    if (!saveValid && errors.length) {
      return
    }

    const {
      data: { id, variables },
      onUpdate,
    } = this.props

    const prepareItem = item => {
      let prepared = {
        label: (item.label || '').trim(),
        name: item.name.trim(),
        required: item.required,
        type: item.type,
      }

      if (item.id) {
        prepared = { id: item.id, ...prepared }
      }

      return prepared
    }

    const newItems = JSON.stringify(
      items
        .filter(i => i.id || i.name)
        .filter((item, i) => !saveValid || this.validateItem(i)())
        .map(prepareItem)
    )

    if (newItems === JSON.stringify(variables)) {
      this.setState({ items: variables })

      return
    }

    onUpdate({ id, variables: newItems })
  }

  getDupicateNames = () => {
    const { items } = this.state

    let newItemNames = items.filter(item => !item.id)

    newItemNames = newItemNames.map(item => item.name)
    newItemNames = newItemNames.filter(item => !!item)

    const counter = {}

    return newItemNames.filter(n => (counter[n] = counter[n] + 1 || 1) === 2)
  }

  renderItem = (item, i) => {
    const { active, error } = this.props
    const { errors } = this.state

    const forbiddenNames = this.getItemForbiddenNames(item)
    const dupicateNames = this.getDupicateNames()

    return (
      <Item
        active={active}
        data={item}
        error={errors.includes(i)}
        incorrectNames={error ? error.unexpected_names || [] : []}
        key={`item-${item.key || item.id}`}
        nameIsForbidden={
          forbiddenNames.includes(item.name) ||
          dupicateNames.includes(item.name)
        }
        onRemove={this.removeItem(i)}
        onUpdate={this.updateItem(i)}
        onValidate={this.validateItem(i)}
      />
    )
  }

  renderItems = () => {
    const { t } = this.props
    const { items } = this.state

    return (
      <div>
        {!!items.length && (
          <div className='request-constructor__card--variable-items-header'>
            <div>{t('userMessage')}:</div>
            <div>{t('type')}:</div>
            <div>{t('variableName')}:</div>
            <div>{t('fieldRequired')}:</div>
          </div>
        )}
        {items.map(this.renderItem)}
      </div>
    )
  }

  render() {
    const { onClickOut, t } = this.props
    const { items } = this.state

    return (
      <OutsideClick onClickOut={() => onClickOut()}>
        <div className='request-constructor__card--variable-body'>
          <div>{t('variableDescription')}</div>
          {this.renderItems()}
        </div>
        {items.length < MAX_VARIABLES_COUNT && (
          <div className='request-constructor__card--footer'>
            <Button.Add title={t('addVariable')} onClick={this.addItem} />
          </div>
        )}
      </OutsideClick>
    )
  }
}

export default withTranslation('RequestConstructor')(Variable)
