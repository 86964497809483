// @flow

import React from 'react'

import SelectAsync from './SelectAsync'

const SelectUser = props => (
  <SelectAsync
    {...props}
    profile
    apiFn={
      props.isDweller
        ? 'profile.getDwellerOptionList'
        : props.isManager
        ? 'profile.getAdminAndManagerOptionList'
        : 'profile.getListTiny'
    }
    valueKey={props.isDweller || props.isManager ? 'id' : 'owner'}
    labelKey='fullname'
    className='bar__userbar'
  />
)

SelectUser.defaultProps = {
  btnPlaceholder: 'Placeholder',
  placeholder: 'FindUser',
}

export default SelectUser
