// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Textarea from 'react-textarea-autosize'
import classnames from 'classnames'
import { difference } from 'lodash-es'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { CLEAR_EDIT_FROM_WIDGET } from '../containers/RequestView/RequestView.constants'
import Modal from './Modal'
import RequestProvidersSelect from './RequestProvidersSelect'
import Search from '../containers/MarketplaceSettings/Search'
import RequestProviderLabels from './RequestProviderLabels'
import NewTemplateDropdown from './NewTemplateDropdown'
import { renderTemplate } from '../core/api/api.template'
import Checkbox from './Checkbox/Checkbox'

import styles from './RequestProviders.module.scss'

const RequestProvidersEditingPopup = props => {
  const { editing, working, contractor, buildingId, automate } = props

  const { t } = useTranslation('Request')
  const { requestID } = useParams()

  let initilalContractors = [...props.contractors]

  if (
    contractor?.id &&
    !initilalContractors.map(c => c.id).includes(contractor.id)
  ) {
    initilalContractors.push(contractor)
  }

  const dispatch = useDispatch()
  const [contractors, setContractors] = useState(initilalContractors)
  const [loading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const [comment, setComment] = useState('')
  const [isCommentActive, setCommentActive] = useState(/*!!providerId*/ false)
  const [isProviderListActive, setProviderListActive] = useState(false)
  const [notify, setNotify] = useState(false)

  const handleSave = () => {
    const params = Object.assign(
      {},
      {
        contractors: contractors.map(c => c.id),
        notify_contractors: notify,
      },
      comment ? { contractor_comment: comment } : undefined
    )
    props.onChange(params)
    props.onChangeContractors && props.onChangeContractors(contractors)
    props.handleClose()
  }

  const handleChangeComment = ({ target: { value: comment } }) =>
    setComment(comment)

  useEffect(() => {
    document.body.style.overflowY = 'hidden'

    return function cleanup() {
      document.body.style.overflowY = 'scroll'

      dispatch({ type: CLEAR_EDIT_FROM_WIDGET })
    }
  }, [])

  const toggleCommentsActive = () =>
    setCommentActive(isCommentActive => !isCommentActive)

  const toggleProviderListActive = () =>
    setProviderListActive(isProviderListActive => !isProviderListActive)

  const hideProvidersList = classnames(
    'modal__label--drop button-drop button-drop--small',
    { 'modal__label--drop--open': isProviderListActive }
  )

  const hideComment = classnames(
    'modal__label--drop button-drop button-drop--small',
    { 'modal__label--drop--open': isCommentActive }
  )

  const isDiff =
    !!difference(
      props.contractors.map(c => c.id),
      contractors.map(c => c.id)
    ).length ||
    !!difference(
      contractors.map(c => c.id),
      props.contractors.map(c => c.id)
    ).length

  const handleSelect = template => {
    renderTemplate(template.id, {
      request_id: requestID,
      providers_ids: contractors.map(c => c.id),
    }).then(data => setComment(comment + data.text))
  }

  const handleChangeNotify = e => setNotify(e.currentTarget.checked)

  return (
    <Modal
      isOpen={editing}
      contentLabel=''
      className='Modal__Bootstrap modal-dialog'
    >
      <div className='modal__title'>{t('AddRequestProvider')}</div>
      <button
        className='modal__close'
        type='button'
        onClick={props.handleClose}
      />
      <div className='modal__body'>
        <Search
          setSearchValue={setSearchValue}
          className={styles.search}
          placeHolder={t('SearchProviderPlaceholder')}
        />
        <RequestProvidersSelect
          contractors={contractors}
          setContractors={setContractors}
          setLoading={setLoading}
          searchValue={searchValue}
        />
        {!!contractors.length && (
          <>
            <div className='modal__row modal__row--margin'>
              <span
                className='modal__label modal__label--margin'
                style={{ cursor: 'pointer' }}
                onClick={toggleProviderListActive}
              >
                <span className='modal__label--black modal__label--margin'>
                  {t('ShowProviderList')}
                </span>
                {contractors.length}
              </span>
              <button
                type='button'
                className={hideProvidersList}
                style={{ marginTop: 0 }}
                onClick={toggleProviderListActive}
              />
            </div>
            {!automate && (
              <Checkbox
                checked={notify}
                text={t('NotifyProviders')}
                style={{ marginTop: '8px' }}
                onChange={handleChangeNotify}
              />
            )}
          </>
        )}
        {!!contractors.length && isProviderListActive && (
          <RequestProviderLabels
            contractors={contractors}
            setContractors={setContractors}
          />
        )}
        {!automate && (
          <div className='modal__row modal__row--margin'>
            <span
              className='modal__label modal__label--margin'
              style={{ cursor: 'pointer' }}
              onClick={toggleCommentsActive}
            >
              <span className='modal__label--black modal__label--margin'>
                {t('ShowCommentInput')}
              </span>
            </span>
            <button
              type='button'
              className={hideComment}
              style={{ marginTop: 0 }}
              onClick={toggleCommentsActive}
            />
          </div>
        )}
        {isCommentActive && (
          <div className='textarea-2 textarea-2--large textarea-2--block textarea-2--default'>
            {requestID && (
              <NewTemplateDropdown
                isProvider
                handleSelect={handleSelect}
                permanentParams={
                  buildingId ? { for_buildings: buildingId } : {}
                }
              />
            )}
            <Textarea
              className='textarea-2__input modal-adduser__comment'
              name='comment'
              placeholder={t('CommentPlaceholder')}
              value={comment}
              minRows={3}
              maxRows={3}
              onChange={handleChangeComment}
            />
          </div>
        )}
      </div>
      <div className='modal__submit'>
        <button
          disabled={working || loading || !isDiff}
          className='button button--large button--success'
          type='submit'
          onClick={handleSave}
        >
          {t('SaveProviders')}
        </button>
        <button
          disabled={loading}
          className='button button--large button--danger-3'
          type='button'
          onClick={props.handleClose}
        >
          {t('Cancel')}
        </button>
      </div>
    </Modal>
  )
}

export default RequestProvidersEditingPopup
