// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'
import { useLoad, usePathname } from '../../hooks'
import { getMailIntegrationList } from '../../core/api/api.mail'
import EmptyList from '../EmptyList'
import BottomPagination from '../Pagination/BottomPagination'
import Loader from '../Loader'
import ToolboxWrapper from './ToolboxWrapper'
import EmailIntegrationTable from './EmailIntegrationTable'
import Modal from '../Modal'
import EmailIntegrationModal from './EmailIntegrationModal'
import addRedirectToFirstPage from '../../hocs/addRedirectToFirstPage'

const Integrations = (props): Node => {
  const { error_message } = props
  const { t } = useTranslation('EmailIntegrationNylas')
  const isLocked = usePathname()
  const [data, isLoading, isError, load] = useLoad(
    getMailIntegrationList,
    {
      archived: false,
    },
    isLocked
  )
  const [addModal, setAddModal] = useState(false)
  const [errorModal, setErrorModal] = useState(null)
  const [removeModal, setRemoveModal] = useState(false)

  useEffect(() => {
    if (error_message) {
      setErrorModal({ message: error_message })
    }
  }, [])

  useEffect(() => {
    if (data) {
      props.setCount(data.meta.count)
    }

    return function cleanup() {
      props.setCount(null)
    }
  }, [data])

  if (isError) {
    return null
  }

  const hideModal = () => setRemoveModal(null)

  const add = isPrivate => {
    setAddModal({ isPrivate })
  }

  const actionOptions = () => {
    return [
      {
        value: 'private',
        label: t('Private'),
        handler: () => add(true),
        icon: 'privateAcc',
      },
      {
        value: 'corporate',
        label: t('Corporate'),
        handler: () => add(false),
        icon: 'corporateAcc',
      },
    ]
  }

  const handleCloseError = () => {
    setErrorModal(null)
  }

  return (
    <>
      {data?.results ? (
        <>
          <ToolboxWrapper
            loading={isLoading}
            actionOptions={actionOptions}
            data={data}
            items={data.results.objects}
            addPrivate={() => add(true)}
          />
          <EmailIntegrationTable
            emails={data.results.objects}
            loading={isLoading}
            setRemoveModal={setRemoveModal}
            hideModal={hideModal}
            load={load}
          />
          {!data.results.objects.length && !isLoading && (
            <EmptyList
              embedded
              canAdd
              icon='email'
              title={t('EmailAccountNotFound')}
            />
          )}
          <BottomPagination meta={data.meta} classes='paginator-2' />
        </>
      ) : (
        <Loader type='big' text={false} />
      )}
      {addModal && (
        <EmailIntegrationModal
          isPrivate={addModal.isPrivate}
          handleCancel={() => setAddModal(null)}
        />
      )}
      {errorModal && (
        <EmailIntegrationModal
          error={errorModal.message}
          handleCancel={() => handleCloseError()}
        />
      )}
      {!!removeModal && (
        <Modal isOpen={!!removeModal} onRequestClose={hideModal}>
          {removeModal}
        </Modal>
      )}
    </>
  )
}

export default addRedirectToFirstPage(Integrations)
