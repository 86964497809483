// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import DateTime from '../../DateTime'
import { Header, CellsBar, Cell, SimpleBlock } from '../../Block'
import { EntityIcon } from '../../Icon'
import LinkedRequests from './LinkedRequests'
import { getUniqAddresseeCount } from '../../../containers/Mail/MailThread/NewMailThread.utils'
import DocIcon from '../../../../static/icons/google-drive.svg'

import styles from './MailThreadInfo.module.scss'

type Props = {|
  created: string,
  docsEnabled?: boolean,
  isMass: boolean,
  messages: ?Array<Object>,
  requests: Array<Object>,
  selected: Array<number>,
  title: string,
  users: Array<Object>,
  uuid: string,
|}

const MailThreadInfo = (props: Props): Node => {
  const { created, uuid, title, requests, messages, isMass, docsEnabled } =
    props

  const { t } = useTranslation('Mail')

  const threadTitle =
    uuid === 'create' ? (isMass ? t('NewMassMessage') : t('NewMessage')) : title

  return (
    <SimpleBlock>
      <Header
        header
        icon={<EntityIcon large id='email' />}
        textTitle={threadTitle}
      >
        {threadTitle}
        {docsEnabled && <DocIcon className={styles.docIcon} />}
      </Header>
      <CellsBar>
        <Cell title={t('Common:CreationDate')}>
          <DateTime dateTime={created} />
        </Cell>
        <Cell title={t('NumberOfParticipants')}>
          {messages ? getUniqAddresseeCount(messages) : 0}
        </Cell>
      </CellsBar>
      {requests && (
        <CellsBar>
          <Cell title={t('LinkedRequests')} valueClassName={styles.value}>
            {requests.length ? (
              <LinkedRequests requests={requests} />
            ) : (
              <span className='bar__cell-value'>{t('NoRequests')}</span>
            )}
          </Cell>
        </CellsBar>
      )}
    </SimpleBlock>
  )
}

export default MailThreadInfo
