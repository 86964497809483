// @flow

import { getApi } from './api.core'

export const getBuildingInfo = id =>
  getApi(`api/building-public/${id}/`, null, false)
export const getContactsUk = params =>
  getApi('api/contact/list/', params, false)
export const getNews = params => getApi('api/newsband-public/', params, false)
export const getTickerNews = params => getApi('api/ticker/', params, false)
