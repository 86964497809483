// @flow

import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { parse } from 'qs'
import { replace, push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import type { Node } from 'react'

import Loader from '../../../components/Loader'
import MailThreadInfo from '../../../components/Mail/MailThreadInfo/MailThreadInfo'
import {
  deleteAsyncTack,
  getThreadMessage,
  getArchivedMessage,
  getThread,
  createThread,
  readArchivedThread,
  readThread,
  getEmail,
  readMessage,
} from '../../../core/api/api.mail'
import { checkPending } from '../../../components/Emails/EmailPending'
import { getCurrentFilters } from '../../../utils/routing'
import { getFetchedItems, isDwellerUser } from '../../../utils/utils'
import NewMailThreadCreate from './NewMailThreadCreate'
import NewMailThreadView from './NewMailThreadView'
import PrintMailThreadPreview from './PrintMailThreadPreview'
import Modal from '../../../components/Modal'
import NewWizard from '../../../components/NewWizard'
import { WIDGET_FILES_INIT } from '../../../components/widgets/WidgetFiles/WidgetFiles.actionTypes'
import { CLEAN_ATTACHED } from '../../../components/modals/AttachToRequestPopup/AttachToRequestPopup.actionTypes'
import { MAIL_SEARCH_SELECT_FILES } from './MailThread.actionTypes'
import { modalError } from '../../../components/Layout/Layout.actions'
import BrowserStorage from '../../../utils/browserStorage'
import { FROM_CREATE, MASS_CREATED } from '../ThreadsList.constants'
import { redirectTo404 } from '../../../utils/routing'

type Props = {
  print?: boolean,
}

const CHECK_ASYNC_TASK_INTERVAL = 1000

const NewMailThread = (props: Props): Node => {
  const location = useLocation()
  const { uuid, muuid } = useParams()
  const { print, isSingle } = props

  const dispatch = useDispatch()

  const timerRef = useRef()

  const { request } = useSelector(state => state.attachToRequestPopup)
  const { selectedFiles } = useSelector(state => state.mailThread)
  const [files, setFiles] = useState([])
  const [messages, setMessages] = useState([])
  const [thread, setThread] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [creatingParams, setCreatingParams] = useState(null)
  const [messageCount, setMessageCount] = useState(null)
  const [isUpdating, setUpdating] = useState(false)
  const [modal, setModal] = useState(null)
  const [convertingUuid, setConvertingUuid] = useState(false)
  const [newThreadUuid, setNewThreadUuid] = useState(null)
  const [updateRequests, setUpdateRequests] = useState(false)
  const {
    soft_archived,
    folder_external_ids,
    exclude_folder_external_ids,
    sub_menu,
  } = getCurrentFilters(location)

  const [isMass, setIsMass] = useState(false)
  const [docsEnabled, setDocsEnabled] = useState(false)
  const [selectedMess, setSelectedMess] = useState({})

  useEffect(() => {
    if (Array.isArray(selectedFiles) && selectedFiles.length) {
      setFiles(selectedFiles)
      dispatch({
        type: MAIL_SEARCH_SELECT_FILES,
        selectedFiles: [],
      })
    }
  }, [])

  useEffect(() => {
    const { send } = parse(location.search, { ignoreQueryPrefix: true })
    setIsMass(send === 'mass' && uuid === 'create')
  }, [])

  useEffect(() => {
    return function cleanup() {
      clearTimeout(timerRef.current)
      dispatch({ type: CLEAN_ATTACHED })
    }
  }, [])

  useEffect(() => {
    if (!isLoading && !updateRequests) {
      return
    }

    const softArchived =
      (soft_archived && JSON.parse(soft_archived)) || sub_menu === 'archive'

    const api = softArchived ? getArchivedMessage : getThreadMessage

    let readApi = softArchived ? readArchivedThread : readThread

    if (isSingle) {
      readApi = readMessage
    }

    if (uuid !== 'create') {
      if (isSingle) {
        dispatch({ type: WIDGET_FILES_INIT, email: uuid })
      } else {
        dispatch({ type: WIDGET_FILES_INIT, thread: uuid })
      }

      readApi(uuid)
    }

    const getApi = isSingle ? getEmail : getThread

    Promise.all(
      uuid !== 'create'
        ? [
            isSingle
              ? undefined
              : api({
                  thread: uuid,
                  page_size: 100500,
                  exclude_folder_external_ids,
                  folder_external_ids,
                  sub_menu,
                }),
            getApi(uuid),
          ]
        : []
    )
      .then(data => {
        if (data[0]) {
          setMessages(getFetchedItems(data[0]))
          setMessageCount(data[0].meta.count)
        }

        if (data[1]) {
          if (isSingle) {
            getThread(data[1].thread).then(thread => {
              setThread({
                created: data[1].created,
                created_requests: data[1].created_requests,
                has_error_message: false,
                has_pending_message: false,
                linked_requests: data[1].linked_requests,
                notes: null,
                owner: data[1].owner,
                requests: data[1].linked_requests,
                shared: false,
                starred: data[1].starred,
                title: thread.title,
                unviewed_msg_count: 0,
                updated: '',
                uuid: data[1].thread,
              })

              setMessages([data[1]])
              setMessageCount(1)
            })
          } else {
            setThread(data[1])
          }

          checkPending([data[1]], () => setLoading(true))
        } else {
          setThread({
            uuid,
            created: new Date().toISOString(),
            title: '',
          })
        }
      })
      .catch(error => redirectTo404(error))
      .finally(() => {
        setLoading(false)
      })
    setUpdateRequests(false)
  }, [isLoading, uuid, updateRequests])

  useEffect(() => {
    if (!isLoading && uuid) {
      setLoading(true)
    }
  }, [uuid])

  useEffect(() => {
    if (newThreadUuid && newThreadUuid !== uuid) {
      dispatch(push(`/mail/${newThreadUuid}`))
    }
  }, [newThreadUuid])

  useEffect(() => {
    if (request && thread) {
      if (!requests.find(req => req.id === request.id)) {
        setThread({ ...thread, requests: [request, ...requests] })
      }
    }
  }, [request])

  useEffect(() => {
    if (creatingParams) {
      createThread({
        ...creatingParams,
        text: creatingParams.text.replace(/\n/g, ''),
      })
        .then(data => {
          if (data.task_id) {
            checkCreateThreadTask(data.task_id)
          } else {
            threadCreated()
          }
        })
        .catch(error => {
          if (error.message.response.status === 400) {
            dispatch(modalError(error.message.response.data))
          }

          setCreatingParams(null)
        })
    }
  }, [creatingParams])

  const checkCreateThreadTask = taskId => {
    deleteAsyncTack(taskId).then(res => {
      if (!res.ready) {
        timerRef.current = setTimeout(
          () => checkCreateThreadTask(taskId),
          CHECK_ASYNC_TASK_INTERVAL
        )
      } else {
        threadCreated()
      }
    })
  }

  const threadCreated = () => {
    BrowserStorage.set(FROM_CREATE, true)
    BrowserStorage.set(MASS_CREATED, isMass)
    dispatch(replace('/mails/sent/'))
  }

  if (isLoading) {
    return <Loader type='big' text={false} />
  }

  if (!thread || !uuid) {
    return null
  }

  const { created, requests, title }: Object = thread

  const mailThreadClass = classnames('mail-thread', {
    'working-overlay': !!creatingParams || isUpdating,
  })

  const handleHide = () => setModal(null)
  const handleCloseWizard = () => {
    setConvertingUuid(null)
    setUpdateRequests(true)
  }

  const toggleMass = mass => {
    setIsMass(mass)
  }

  return (
    <div className={mailThreadClass}>
      {!print && (
        <MailThreadInfo
          isMass={isMass}
          docsEnabled={docsEnabled}
          uuid={uuid}
          title={title}
          created={created}
          requests={requests}
          messages={messages}
        />
      )}
      {uuid === 'create' ? (
        <NewMailThreadCreate
          files={files}
          thread={thread}
          isMass={isMass}
          onCreate={setCreatingParams}
          onDocsEnabled={setDocsEnabled}
          onToggleMass={toggleMass}
        />
      ) : print ? (
        <PrintMailThreadPreview
          messageCount={messageCount}
          messages={messages}
          title={title}
          messageUuid={muuid}
          working={isLoading}
        />
      ) : (
        <NewMailThreadView
          messageCount={messageCount}
          messages={messages}
          thread={thread}
          isSingle={isSingle}
          setNewThreadUuid={setNewThreadUuid}
          setUpdating={setUpdating}
          setModal={setModal}
          hideModal={handleHide}
          setLoading={setLoading}
          isMass={isMass}
          setSelectedOwner={mess => {
            setSelectedMess(messages.find(i => i.uuid === mess))
          }}
          onOpenWizard={setConvertingUuid}
          onToggleMass={toggleMass}
        />
      )}
      <Modal isOpen={!!modal} onRequestClose={handleHide}>
        {modal}
      </Modal>
      {convertingUuid && (
        <NewWizard
          messageUuid={convertingUuid}
          init={{
            user:
              selectedMess?.uuid && isDwellerUser(selectedMess?.owner_obj)
                ? selectedMess?.owner_obj?.id
                : null,
          }}
          onClose={handleCloseWizard}
          onReload={() => setLoading(true)}
        />
      )}
    </div>
  )
}

export default NewMailThread
