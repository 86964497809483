// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Row } from '../../Block'
import Button from '../../Button'
import SelectUser from '../../Select/SelectUser'
import SelectValue from '../../Select/ValueItem'
import { USER_GROUPS, USER_STATUS, ACTIVE_STATUS } from '../../../constants'
import { getUrlForAvatar, getUserId } from '../../../utils/utils'
import { getUser } from '../../../utils/commonSelectors'

import styles from './ManagersBar.module.scss'

const Mangers = props => {
  const { t } = useTranslation('Building')
  const {
    data: { owner_objs: owners, permissions = {} },
    working,
    editingManagers,
    title,
    margin,
    max,
  } = props
  const {
    can_edit_manager: canEdit,
    can_manage_responsible_users: canEditManagers,
  } = permissions
  const selectParams = {
    all: true,
    page_size: max || undefined,
  }

  const onEdit = canEdit || canEditManagers ? props.editManagers : null
  const user = useSelector(state => getUser(state))

  const getName = man =>
    man.name ? `${man.name} ${man.second_name || ''}` : man.fullname
  const renderManager = man => (
    <div className={styles.manager} key={`worker-${getUserId(man) || ''}`}>
      <SelectValue
        inline
        profile
        round
        isLink={
          !(
            user.group === USER_GROUPS.dweller &&
            USER_STATUS[man.status] !== ACTIVE_STATUS
          )
        }
        avatar={getUrlForAvatar(man)}
        profileId={man.id}
        group={man.group}
        text={getName(man)}
      />
    </div>
  )

  if (editingManagers) {
    const initialIds = owners.map(getUserId)

    return (
      <Row title={title || t('Manager')}>
        <SelectUser
          cleanable
          multi
          explicitLoading
          isManager
          order
          round
          margin={margin}
          id='building-manager'
          initialValueId={initialIds}
          params={selectParams}
          onChange={props.updateManagers}
        />
        <div
          style={{
            marginTop: '15px',
            marginBottom: '5px',
          }}
        >
          {props.saveManagers && (
            <Button.Save working={working} onClick={props.saveManagers}>
              {t('Save')}
            </Button.Save>
          )}
          {props.cancelManagers && (
            <Button.Cancel onClick={props.cancelManagers}>
              {t('Cancel')}
            </Button.Cancel>
          )}
        </div>
      </Row>
    )
  }

  return (
    <Row title={title || t('Manager')} onEdit={onEdit}>
      {owners.length ? (
        <div className={styles.managers}>{owners.map(renderManager)}</div>
      ) : (
        <span className='bar__submit-label_light bar__submit-label'>
          {t('SmthEmpty')}
        </span>
      )}
    </Row>
  )
}

export default Mangers
