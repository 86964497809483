// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import type { Node } from 'react'

import Loader from '../Loader'
import NewTable from '../NewTable'
import NewTableHeader from '../NewTable/NewTableHeader'
import NewTableHeaderRow from '../NewTable/NewTableHeaderRow'
import NewTableHeaderCell from '../NewTable/NewTableHeaderCell'
import NewTableBody from '../NewTable/NewTableBody'
import AccountItem from './AccountItem'

import { getAccountsOrderingList } from '../../core/api/api.mail'

const Accounts = (props): Node => {
  const { t } = useTranslation('EmailIntegrationNylas')

  const [init, setInit] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!init || loading) {
      getAccountsOrderingList().then(data => {
        setAccounts(data.results.objects)
        props.setCount(data.meta.count)
        setInit(true)

        if (loading) {
          setLoading(false)
        }
      })
    }
  }, [init, loading])

  return (
    <>
      {!init ? (
        <Loader type='big' text={false} />
      ) : (
        <DndProvider backend={HTML5Backend}>
          <NewTable loading={loading}>
            <NewTableHeader>
              <NewTableHeaderRow>
                <NewTableHeaderCell
                  title={t('EmailAccount')}
                  style={{ width: '50%' }}
                />
                <NewTableHeaderCell
                  title={t('TypeAccount')}
                  style={{ width: '50%' }}
                />
              </NewTableHeaderRow>
            </NewTableHeader>
            <NewTableBody>
              {accounts.map(account => (
                <AccountItem
                  key={account.id}
                  account={account}
                  setLoading={setLoading}
                />
              ))}
            </NewTableBody>
          </NewTable>
        </DndProvider>
      )}
    </>
  )
}

export default Accounts
