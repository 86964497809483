import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDrag, useDrop } from 'react-dnd'
import message from 'antd/es/message'

import NewTableBodyRow from '../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../NewTable/NewTableBodyCell'

import Icon from '../Icon'

import { updateAccountOrdering } from '../../core/api/api.mail'

import styles from './EmailIntegrationTable/EmailIntegrationTableBodyRow.module.scss'

const AccountItem = props => {
  const {
    account: { id, email, corporate, need_reconnect, position },
  } = props

  const { t } = useTranslation('EmailIntegrationNylas')

  const ref = useRef(null)

  const [isHover, setHover] = useState(false)

  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => setHover(false)

  const needReconnectMessage = need_reconnect ? t('NeedReconnect') : null

  const getAccountType = corporate =>
    corporate ? t('Corporate') : t('Private')

  const [, drag] = useDrag({
    item: {
      id,
      type: 'account',
      name,
      position,
    },
    canDrag: () => true,
    collect: monitor => {
      return {
        isDragging: monitor.isDragging(),
      }
    },
  })

  const [, drop] = useDrop({
    accept: 'account',
    canDrop: (dropProps, monitor) => {
      const { id: targetId } = monitor.getItem()

      return targetId !== id
    },
    drop: (dropProps, monitor) => {
      const { id: targetId } = monitor.getItem()

      if (id === targetId) {
        return
      }

      updateAccountOrdering(targetId, { position })
        .then(() => {
          message.success(t('OrderChanged'))
          props.setLoading(true)
        })
        .catch(() => message.error(t('OrderError')))
    },
    collect: monitor => ({
      isDropTarget: monitor.canDrop() && monitor.isOver({ shallow: true }),
    }),
  })

  drag(drop(ref))

  return (
    <NewTableBodyRow ref={ref}>
      <NewTableBodyCell>
        <Icon id='drag' />
        <div className='table-item table-item__text' title={email}>
          <span>{email}</span>
        </div>
        {needReconnectMessage && (
          <div
            className={styles.needReconnect}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Icon id='repeat' className={styles.icon} />
            {isHover && (
              <div className={styles.tooltip}>
                <div />
                <span>{needReconnectMessage}</span>
              </div>
            )}
          </div>
        )}
      </NewTableBodyCell>
      <NewTableBodyCell title={getAccountType(corporate)} />
    </NewTableBodyRow>
  )
}

export default AccountItem
