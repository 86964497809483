// @flow

import React, { useRef, useState } from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Icon from '../../../components/Icon'

import styles from './ThreadsListItemRequests.module.scss'

import RequestSearch from './RequestSearch'
import { STATUSES_COLORS } from '../../../ui/MainColors.constants'

type Props = { requests: Array<Object>, viewedCount: number }

const STATUS_BODY = [null, 'opened', 'scheduled', 'completed', 'rejected']
const STATUS_CSS = [null, 'danger', 'warning', 'success', 'deny']

const ThreadsListItemRequests = (props: Props): Node => {
  const { requests = [], viewedCount = 3 } = props

  const { t } = useTranslation('Mail')

  const [menuIsOpen, setMenuOpen] = useState(false)
  const [position, setPosition] = useState('bottom')

  const selectRef = useRef(null)
  const handleClick = e => e.stopPropagation()

  const handleClickForSearch = e => {
    handleClick(e)

    if (!menuIsOpen && selectRef.current) {
      const dropDownLen = requests.length > 6 ? 269 : requests.length * 32 + 57

      const isBottom =
        window.innerHeight - selectRef.current.getBoundingClientRect().bottom >
        dropDownLen

      setPosition(isBottom ? 'bottom' : 'top')
    }

    setMenuOpen(true)
  }

  if (!requests || !requests.length) {
    return null
  }

  const handleClickOut = () => {
    setMenuOpen(false)
  }

  const getStatusObj = status => ({
    body: STATUS_BODY[status],
    css: STATUS_CSS[status],
  })

  const formatRequest = request => ({
    ...request,
    status_obj: request.status_obj || getStatusObj(request.status),
  })

  return (
    <div className={styles.requests} onClick={handleClick}>
      <Icon id='link' className={styles.icon} />
      <span className={styles.title}>{t('LinkedRequests')}</span>
      {requests
        .slice(0, viewedCount)
        .map(formatRequest)
        .map(request => (
          <span key={request.id} className={styles.link}>
            {request.is_visible === false ? (
              <span className={styles.text}>
                {`${t('Common:NumberSymbol')}${request.request_no}`}{' '}
              </span>
            ) : (
              <Link to={`/request/${request.id}`}>
                {`${t('Common:NumberSymbol')}${request.request_no}`}{' '}
              </Link>
            )}
            {request?.status_obj && (
              <span
                className={styles.status}
                style={{
                  color: STATUSES_COLORS[request?.status_obj?.css],
                }}
              >
                {`(${t(
                  `Request:${
                    request?.status_obj?.body || request?.status_obj?.title
                  }`
                )})`}
              </span>
            )}
          </span>
        ))}
      {requests.length > viewedCount && (
        <div
          className={styles.count}
          ref={selectRef}
          onClick={handleClickForSearch}
        >
          {`+${requests.length - viewedCount}`}
          {menuIsOpen && (
            <RequestSearch
              handleClickOut={handleClickOut}
              position={position}
              searchKey={'request_no'}
              items={requests}
              setMenuOpen={setMenuOpen}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default ThreadsListItemRequests
