// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { v1 as uuid } from 'uuid'
import { isEqual } from 'lodash-es'
import { compose } from 'redux'

import Header from './Header'
import NewsPage from './NewsPage'
import ContactInfo from './ContactInfo'
import NewsBand from './NewsBand'
import NoInternetPage from '../../components/NoInternetPage'
import * as selectors from './PublicBuildingPage.selectors'
import {
  loadNews,
  loadTickerNews,
  loadBuilding,
  loadInit,
} from './PublicBuildingPage.actions'

import { getBuildVersion } from '../../core/api/api.system'

const MINUTE = 1000 * 60
const INTERVAL = MINUTE
const BANNER_TIMEOUT = MINUTE * 30
const INIT_INTERVAL = MINUTE * 60
const VERSION_INTERVAL = MINUTE * 60

type Props = {
  isError: Object,
  isInitialLoading: boolean,
  t: string => string,
  version: ?string,
}
type State = {
  isVisibleBanner: boolean,
  version: string,
}

class PublicBuildingPage extends Component<Props, State> {
  state = {
    isVisibleBanner: false,
    newsPageKey: uuid(),
    tickerKey: uuid(),
  }

  componentDidMount() {
    this.props.loadNews()
    this.props.loadTickerNews()
    this.loadVersion()
    this.interval = setInterval(this.props.loadNews, INTERVAL)
    this.interval = setInterval(this.props.loadTickerNews, INTERVAL)
    this.initInterval = setInterval(this.props.loadInit, INIT_INTERVAL)
    this.initInterval = setInterval(this.loadVersion, VERSION_INTERVAL)
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.state.version &&
      prevState.version &&
      prevState.version !== this.state.version
    ) {
      window.location.reload(true)
    }

    if (
      this.props.isError !== prevProps.isError &&
      'news' in this.props.isError
    ) {
      this.bannerTimeout = setTimeout(() => {
        this.setState({ isVisibleBanner: true })
      }, BANNER_TIMEOUT)
    } else if (prevState.isVisibleBanner !== this.state.isVisibleBanner) {
      clearTimeout(this.bannerTimeout)
      this.setState({ isVisibleBanner: false })
    }

    if (!isEqual(this.props.news, prevProps.news)) {
      this.setState({ newsPageKey: uuid() })
    }

    if (!isEqual(this.props.tickerNews, prevProps.tickerNews)) {
      this.setState({ tickerKey: uuid() })
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    clearInterval(this.initInterval)
  }

  loadVersion = () => {
    getBuildVersion()
      .then(res => this.setState({ version: res }))
      .catch(error => console.error(error))
  }

  render() {
    const { t, isInitialLoading } = this.props
    const { isVisibleBanner, newsPageKey, tickerKey } = this.state

    if (isInitialLoading) {
      return <div className='site_wrap public-building-page' />
    }

    return (
      <div className='site_wrap public-building-page'>
        <Header />
        <main>
          <div className='public-building-page__news'>
            <NewsPage key={newsPageKey} />
            <NewsBand key={tickerKey} />
            {isVisibleBanner && (
              <NoInternetPage
                className='no-internet__image-right'
                title={t('no internet component title')}
                subTitle={t('no internet component description')}
                text={t('no internet component action')}
              />
            )}
          </div>
          <ContactInfo />
        </main>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  version: selectors.getVersion(state),
  isError: selectors.isError(state),
  isInitialLoading: selectors.isInitialLoading(state),
  news: selectors.getNewsList(state),
  tickerNews: selectors.getTickerNewsList(state),
})

const mapDispatchToProps = {
  loadNews,
  loadTickerNews,
  loadBuilding,
  loadInit,
}

export default compose(
  withTranslation('PublicBuildingPage'),
  connect(mapStateToProps, mapDispatchToProps)
)(PublicBuildingPage)
