// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Joi from 'joi-browser'
import validation from 'react-validation-mixin'
import validationStrategy from 'joi-browser-validation-strategy'
import { get, join } from 'lodash-es'
import { compose } from 'redux'

import * as actions from './AttachToRequestPopup.actionTypes'
import NewSelectAsync from '../../NewSelectAsync'
import ConfirmRestorePopup from './ConfirmRestorePopup'
import {
  getRequestListLight,
  getRequestListCount,
} from '../../../core/api/api.request'

import styles from './AttachToRequestPopup.module.scss'

type Props = {
  chatMessageId?: number,
  dispatch: Object => void,
  emailMessageId?: number,
  isValid: boolean,
  move?: boolean,
  onClose: () => void,
  onSave?: () => void,
  requestIds?: string,
  t: string => string,
}
type State = {
  request: Object | null,
  request_status: number | null,
  restorePopup: boolean,
}

class AttachToRequestPopup extends Component<Props, State> {
  constructor(props) {
    super(props)
    const reqText = `!! ${this.props.t('Common:FieldIsRequired')}`
    this.validationMsg = {
      language: {
        any: {
          invalid: reqText,
          empty: reqText,
        },

        string: {
          base: reqText,
          empty: reqText,
          required: reqText,
        },
        object: {
          base: reqText,
          empty: reqText,
          required: reqText,
        },
      },
    }

    this.validatorTypes = {
      request: Joi.object().required().options(this.validationMsg),
    }
  }

  state = {
    request: null,
    request_status: null,
    restorePopup: null,
  }

  componentDidMount() {
    this.props.dispatch({
      type: actions.INIT,
    })
    document.body.style.overflowY = 'hidden'
  }

  componentDidUpdate(prevProps) {
    const { working, error } = this.props

    if (prevProps.working && !working && !error) {
      this.props.onClose()
      this.props.onSave && this.props.onSave()
    }
  }

  getRequestTitle = request => {
    return `${this.props.t('Request:request_number_title')}${
      request.request_no
    }: ${request.title}`
  }

  getMeta = option => {
    if (!option || !option.archived) {
      return null
    }

    return <span className={styles.archived}>{this.props.t('Archived')}</span>
  }

  componentWillUnmount() {
    document.body.style.overflowY = 'scroll'
  }

  onSelectRequest = request => {
    const { validate } = this.props

    this.setState({ request }, () => validate())
  }

  onClickOkButton = () => {
    const { request } = this.state
    const { move } = this.props

    if (!move && request.archived) {
      this.setState({ restorePopup: true })
    } else {
      this.onConfirm()
    }
  }

  closeConfirmPopup = () => {
    this.setState({ restorePopup: false })
  }

  onConfirm = request_status => {
    const { request } = this.state
    const {
      validate,
      emailMessageId,
      chatMessageId,
      move,
      requestIds,
      isChatroomPublic,
    } = this.props

    validate(err => {
      if (!err) {
        const params = {
          emailMessageId,
          chatMessageId,
          isChatroomPublic,
          requestId: request.id,
          requestTitle: request.title,
          requestNo: request.request_no,
          requestStatus: request_status || request.status_obj,
          request_status: request_status?.id || undefined,
        }

        if (move) {
          this.props.dispatch({
            type: actions.MOVE,
            params: {
              uuid: emailMessageId,
              oldRequestId: requestIds,
              newRequestId: request.id,
            },
          })
        } else {
          this.props.dispatch({
            type: actions.ATTACH,
            params,
          })
        }
      }
    })
  }

  getValidatorData() {
    return this.state
  }

  renderError = field => {
    const { getValidationMessages } = this.props
    const errors = getValidationMessages(field)
    const html = join(errors, '<br/>')

    return errors.length ? (
      <div className='error' dangerouslySetInnerHTML={{ __html: html }} />
    ) : null
  }

  render() {
    const { onClose, t, isValid, working, requestIds, move } = this.props

    const api = async params => {
      const data = await getRequestListLight({ ...params, all: true })
      const count = await getRequestListCount({ ...params, all: true })

      return { ...data, meta: { ...data.meta, ...count } }
    }

    return (
      <>
        <div className='modal__content convert-to-request-popup'>
          <button className='modal__close' type='button' onClick={onClose} />
          <div className='modal__title modal__title--big-bottom-margin'>
            {t(move ? 'MoveThreadTitle' : 'Title')}
          </div>
          <div className='modal__text'>
            <div className='modal__row'>{t('RequestSelectTitle')}</div>

            <div className='modal__row input input--medium input--default input--block'>
              <NewSelectAsync
                pageSize={10}
                api={api}
                searchKey='search'
                getLabel={this.getRequestTitle}
                getSelectedLabel={this.getRequestTitle}
                getMeta={this.getMeta}
                placeholder={this.props.t('Select:SelectRequest')}
                permanentParams={
                  requestIds ? { exclude_ids: requestIds } : null
                }
                view='dropdown'
                getDescription={option => get(option, ['address_obj', 'value'])}
                selectedItems={this.state.request ? [this.state.request] : []}
                className={styles.select}
                onClick={this.onSelectRequest}
              />
              {this.renderError('request')}
            </div>
          </div>

          <div className='modal__submit'>
            <button
              type='button'
              className='button button--large button--success'
              disabled={!isValid || working || !this.state.request}
              onClick={this.onClickOkButton}
            >
              {working && <span className='button__spinner' />}
              {t(move ? 'Move' : 'Attach')}
            </button>
            <button
              type='button'
              className='button button--large button--danger-3'
              onClick={onClose}
            >
              {t('Common:Cancel')}
            </button>
          </div>
          {working && <div className='overlay' />}
        </div>
        {this.state.restorePopup ? (
          <ConfirmRestorePopup
            isOpen
            onClose={this.closeConfirmPopup}
            onOk={this.onConfirm}
          />
        ) : null}
      </>
    )
  }
}

const mapStateToProps = state => state.attachToRequestPopup

export default compose(
  withTranslation('AttachToRequestPopup'),
  connect(mapStateToProps),
  validation(validationStrategy)
)(AttachToRequestPopup)
