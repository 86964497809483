// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom'

import RouterTabs from '../Tabs/RouterTabs'
import Pan from '../Tabs/Pan'
import Integrations from './Integrations'
import Accounts from './Accounts'

const EmailIntegrationNew = (): Node => {
  const { t } = useTranslation('EmailIntegrationNylas')
  const location = useLocation()
  const match = useRouteMatch()

  const [count, setCount] = useState(null)

  const {
    params: { tab },
  } = match

  if (tab && tab !== 'integrations' && tab !== 'accounts') {
    return <Redirect to={`/settings/email-integration/integrations/${tab}`} />
  }

  const queryParams = new URLSearchParams(location.search)
  let error_message = null

  if (queryParams.has('error_message')) {
    error_message = queryParams.get('error_message')
  }

  return (
    <div className='content__col' style={{ paddingTop: '20px' }}>
      <section className='settings-rights unit unit--default'>
        <div className='bar__title-indented bar__row'>
          <div className='bar__title-subtitle'>
            <span className='bar__title-text'>
              {t('EmailIntegrationTitle')}
            </span>
          </div>
        </div>
        <RouterTabs count={count} openFirst='integrations'>
          <Pan label={t('MyIntegrations')} linkHash='#integrations'>
            <Integrations
              key='integrations'
              setCount={setCount}
              error_message={error_message}
            />
          </Pan>
          <Pan label={t('AllAccounts')} linkHash='#accounts'>
            <Accounts key='accounts' setCount={setCount} />
          </Pan>
        </RouterTabs>
      </section>
    </div>
  )
}

export default EmailIntegrationNew
