// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import type { Node } from 'react'

import Modal from '../../Modal'
import { useOverflow } from '../../../hooks'

import ModalHead from '../../Modal/ModalHead'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import ModalBody from '../../Modal/ModalBody'
import ModalButtons from '../../Modal/ModalButtons'
import Button from '../../Button'
import Radio from '../../Radio'
import ModalRow from '../../Modal/ModalRow'
import ModalLabel from '../../Modal/ModalLabel'

import NewSelectSimple from '../../NewSelectSimple'

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onOk: (Object | null) => void,
}

const ConfirmRestorePopup = (props: Props): Node => {
  const { t } = useTranslation('AttachToRequestPopup')
  useOverflow()

  const [restore, setRestore] = useState(false)

  const requestStatuses = useSelector(state => state.init.request_status)
  const options = requestStatuses.map(status => ({
    ...status,
    value: status.id,
    label: t(`Request:${status.title}`),
  }))

  const [status, setStatus] = useState(null)
  const [working, setWorking] = useState(false)

  const handleChangeRestore = e => {
    const val = JSON.parse(e.currentTarget.value)
    setRestore(val)

    if (val) {
      setStatus(options[0])
    } else {
      setStatus(null)
    }
  }

  const handleSave = () => {
    setWorking(true)
    props.onOk(status)
  }

  return (
    <Modal isOpen onRequestClose={props.onClose}>
      <ModalHead title={t('RequestRestoreTitle')} />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody>
        <ModalRow>
          <ModalLabel>{t('RestoreRequestYesNo')}</ModalLabel>
        </ModalRow>
        <ModalRow>
          <Radio
            id='yes'
            name={'restore_request'}
            value={true}
            checked={restore}
            label={t('Yes')}
            onChange={handleChangeRestore}
          />
        </ModalRow>
        <ModalRow>
          <Radio
            id='no'
            name={'restore_request'}
            value={false}
            checked={!restore}
            label={t('No')}
            onChange={handleChangeRestore}
          />
        </ModalRow>
        {restore && (
          <>
            <ModalRow>
              <ModalLabel>{t('RestoreRequestStatus')}</ModalLabel>
            </ModalRow>
            <ModalRow>
              <NewSelectSimple
                options={options}
                value={status}
                style={{ width: '100%' }}
                onChange={setStatus}
              />
            </ModalRow>
          </>
        )}
        <ModalButtons>
          <Button.Save working={working} onClick={handleSave}>
            {t('Common:Ok')}
          </Button.Save>
          <Button.Cancel onClick={props.onClose}>
            {t('Common:Cancel')}
          </Button.Cancel>
        </ModalButtons>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmRestorePopup
