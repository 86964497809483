// @flow

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Textarea from 'react-textarea-autosize'
import { uniqBy } from 'lodash-es'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

import { renderTemplate } from '../../../core/api/api.template'
import Member from '../../Select/ValueItem'
import UserSuggest from '../../UserSuggest/UserSuggest'
import { getUrlForAvatar, getUserId } from '../../../utils/utils'
import * as actionCreators from './UserPopup.actions'
import NewTemplateDropdown from '../../NewTemplateDropdown'

function createProfileFromTag(tag) {
  return tag.profile
    ? tag.profile
    : {
        fullname: tag.name,
        name: tag.name,
        email: tag.name,
        avatar: '/static/img/icon_user.png',
      }
}

class UserPopup extends Component {
  static propTypes = {
    chatroomUuid: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    params: PropTypes.object,
    assignees: PropTypes.array.isRequired,
    t: PropTypes.func,
    data: PropTypes.array,
    comment: PropTypes.string,
    users: PropTypes.array,
    withComment: PropTypes.bool,
    title: PropTypes.string,
    inputLabel: PropTypes.string,
    inputPlaceholder: PropTypes.string,
    okText: PropTypes.string,
    profileCheck: PropTypes.bool,
    emailCheck: PropTypes.bool,
    initUsers: PropTypes.func,
    selectChatMemberInitiating: PropTypes.func,
    addUsers: PropTypes.func,
    changeComment: PropTypes.func,
    removeAssignee: PropTypes.func,
    chatOwner: PropTypes.number,
    onlyManagers: PropTypes.bool,
  }

  state = {
    isCommentShown: false,
    formChanged: false,
    hasRemovedAssignees: false,
    isValid: true,
    filterType: 'user',
  }

  componentDidMount = () => {
    document.body.style.overflowY = 'hidden'

    if (this.props.initialValue) {
      this.setState({ isCommentShown: true })
    }

    this.props.initUsers(this.props.data)
  }

  componentWillUnmount = () => {
    document.body.style.overflowY = 'scroll'
    this.props.clearState()
  }

  onClickOkButton = () => {
    const {
      assignees,
      users,
      comment,
      onClose,
      chatroomUuid,
      notCloseOnSave,
      addChatMembers,
    } = this.props

    const valueId = assignees.map(getUserId)
    const assigneesFromTags = assignees.concat(users.map(createProfileFromTag))
    const uniqueUsers = uniqBy(
      assigneesFromTags,
      tag => getUserId(tag) || tag.name
    )

    this.props.onSave({ users: uniqueUsers, comment })

    if (chatroomUuid) {
      this.props.selectChatMemberInitiating({
        valueId,
        chatroomUuid,
        addChatMembers,
      })
    }

    if (!notCloseOnSave) {
      onClose()
    }
  }

  onChangeComment = ({ target: { value: comment } }) => {
    this.props.changeComment(comment)
  }

  onChangeAssignees = users => {
    this.props.addUsers(users)
    this.setState({ formChanged: true })
  }

  onValidateSuggest = isValid => {
    this.setState({ isValid })
  }

  showCommentForm = () => {
    this.setState({ isCommentShown: true })
  }

  removeMember = user => {
    this.props.removeAssignee(user)
    this.setState({ hasRemovedAssignees: true })
  }

  renderUser = user => {
    const { chatOwner } = this.props
    const id = getUserId(user)
    const text =
      user.fullname ||
      `${user.name} ${user.second_name ? user.second_name : ''}`

    return (
      <Member
        inline
        profile
        round
        avatar={getUrlForAvatar(user)}
        cleanable={!chatOwner || (chatOwner && id !== chatOwner)}
        id={`user-${id}`}
        key={`user-${id}`}
        text={text}
        onRemove={() => this.removeMember(user)}
      />
    )
  }

  renderUsers = () => {
    const { assignees } = this.props

    if (!assignees) {
      return null
    }

    return assignees.map(this.renderUser)
  }

  handleSelect = template => {
    const {
      match: {
        params: { requestID },
      },
      comment,
    } = this.props

    renderTemplate(template.id, { request_id: requestID }).then(data =>
      this.props.changeComment(comment + data.text)
    )
  }

  render() {
    const {
      onClose,
      t,
      comment,
      assignees,
      users,
      title,
      inputLabel,
      inputPlaceholder,
      withComment,
      params,
      profileCheck,
      emailCheck,
      onlyManagers,
      okText,
      building,
      category,
      providerRemoved,
      addChatMembers,
      isActive,
      match,
    } = this.props

    const {
      params: { requestID },
    } = match

    const { isCommentShown, isValid, formChanged, hasRemovedAssignees } =
      this.state

    const enableSubmit =
      isValid &&
      (hasRemovedAssignees || (formChanged && users.length) || providerRemoved)

    return (
      <div className='modal__content'>
        <button className='modal__close' type='button' onClick={onClose} />
        <div className='modal__title modal__title--big-bottom-margin'>
          {title}
        </div>
        <div className='modal__body'>
          <div className='modal-adduser__assignees'>{this.renderUsers()}</div>
          <div className='modal-adduser__row'>
            <span className='modal-adduser__cell  modal__label'>
              {inputLabel}
            </span>
          </div>
          <UserSuggest
            addChatMembers={addChatMembers}
            isActive={isActive}
            building={building}
            category={category}
            emailCheck={emailCheck}
            id='request-initiator'
            placeholder={inputPlaceholder}
            ignoredUsers={assignees}
            profileCheck={profileCheck}
            params={params}
            onlyManagers={onlyManagers}
            initialValue={this.props.initialValue}
            onChange={this.onChangeAssignees}
            onChangeInput={this.showCommentForm}
            onValidate={this.onValidateSuggest}
          />
          {isCommentShown && withComment && (
            <div className='textarea-2 textarea-2--large textarea-2--block textarea-2--default'>
              {requestID && (
                <NewTemplateDropdown
                  isManager
                  handleSelect={this.handleSelect}
                  permanentParams={building ? { for_buildings: building } : {}}
                />
              )}
              <Textarea
                className='textarea-2__input modal-adduser__comment'
                name='comment'
                placeholder={t('CommentPlaceholder')}
                value={comment}
                minRows={3}
                maxRows={3}
                onChange={this.onChangeComment}
              />
            </div>
          )}
        </div>
        <div className='modal__submit'>
          <button
            type='button'
            className='button button--large button--success'
            disabled={!enableSubmit}
            onClick={this.onClickOkButton}
          >
            {okText || t('PopupUserOk')}
          </button>
          <button
            type='button'
            className='button button--large button--danger-3'
            onClick={onClose}
          >
            {t('Cancel')}
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.userPopup,
  chatroomUuid: state.chatroom.chatroomInfo && state.chatroom.chatroomInfo.uuid,
})

export default compose(
  withTranslation('UserPopup'),
  withRouter,
  connect(mapStateToProps, actionCreators)
)(UserPopup)
