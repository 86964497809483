// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import type { Node } from 'react'
import { isEqual } from 'lodash-es'

import { getTemplates } from '../../core/api/api.template'
import { getTemplates as getPostTemplates } from '../../core/api/api.postTemplate'
import { getEmailTemplates } from '../../core/api/api.emailTemplate'
import NewSelectAsync from '../NewSelectAsync'

import Icon from '../Icon'

import styles from './NewTemplateDropdown.module.scss'

type Props = {
  handleSelect: Object => void,
  isAutomation?: boolean,
  isEmail?: boolean,
  isFeedback?: boolean,
  isManager?: boolean,
  isMass?: boolean,
  isPost?: boolean,
  isPostTemplate?: boolean,
  isProvider?: boolean,
  isRequest?: boolean,
  isRequestThread?: boolean,
  permanentParams?: Object,
  showHeader?: boolean,
}

const NewTemplateDropdown = (props: Props): Node => {
  const {
    isRequest,
    isPost,
    isFeedback,
    isProvider,
    isEmail,
    isMass,
    isManager,
    isPostTemplate,
    isRequestThread,
    isAutomation,
    showHeader,
    permanentParams,
  } = props

  const [isOpen, setOpen] = useState(false)
  const [dynamicParams, setDynamicParams] = useState({})
  const { t } = useTranslation('Templates')

  const params: {
    hidden: boolean,
    ordering: string,
    page_size: number,
    tag?: string,
  } = {
    ordering: 'name',
    hidden: false,
  }

  if (isMass) {
    params.tag = 'mass_emailing'
  } else if (isRequestThread) {
    params.tag = 'requestthread_email'
  } else if (isPost) {
    params.tag = 'post_comment'
  } else if (isFeedback) {
    params.tag = 'request_feedback'
  } else if (isProvider) {
    params.tag = 'provider_email'
  } else if (isManager) {
    params.tag = 'manager_email'
  } else if (isRequest) {
    params.tag = 'request_comment'
  } else if (isAutomation) {
    params.tag = 'email,requestthread_email'
  } else if (isEmail) {
    params.tag = 'email'
  } else {
    if (!isPostTemplate) {
      params.tag = 'chat'
    }
  }

  let api = getTemplates

  if (isEmail) {
    api = getEmailTemplates
  }

  if (isPostTemplate) {
    api = getPostTemplates
  }

  const additionalParams = Object.assign(
    {},
    permanentParams ? permanentParams : {},
    isMass ? { tag: 'mass_emailing' } : {}
  )

  if (!isEqual(additionalParams, dynamicParams)) {
    setDynamicParams(additionalParams)
  }

  const selectApi = async params => {
    const data = await api(params)

    return {
      meta: {
        count: data.count,
        curr_page: params.page,
        limit: params.page_size,
        page_count: data.pages,
      },
      results: {
        objects: data.objects.map(o => ({
          ...o,
          avatar: o.private && (
            <Icon
              id='lock'
              style={{ width: '14px', height: '14px', marginRight: '4px' }}
            />
          ),
        })),
      },
    }
  }

  const selectClass = classnames(styles.select, { [styles.open]: isOpen })

  return (
    <>
      {showHeader && (
        <label className={styles.label}>{`${t('Templates')}:`}</label>
      )}
      <NewSelectAsync
        className={selectClass}
        avatarKey='avatar'
        menuClass={styles.menu}
        pageSize={10}
        api={selectApi}
        searchKey='search'
        placeholder={t('ChooseTemplate')}
        getLabel={t => t.name}
        getSelectedLabel={t => t.name}
        view='dropdown'
        permanentParams={params}
        dynamicParams={dynamicParams}
        toggleMenuCb={setOpen}
        onClick={option => props.handleSelect(option)}
      />
    </>
  )
}

export default NewTemplateDropdown
